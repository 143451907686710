export const DropdownItems = [
    {
        ItemName: 'Installation',
        url: '/user-guide',
        cName: 'nav-link'
    },
    {
        ItemName: 'Tech Support',
        url: '/technical-support',
        cName: 'nav-link'
    },
    {
        ItemName: 'Video Training',
        url: '/demo-training',
        cName: 'nav-link'
    }
]