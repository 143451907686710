import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import '../css/auth.css'
import Swal from 'sweetalert2'
import { countries, companies_size } from '../../countries'
import axios from 'axios';
import { API_URL } from '../../App';
import { useNavigate } from 'react-router-dom';
// import CompanySizeSelect from '../partials/CompanySizeSelect';


// const styles = {
//     select: {
//         width: "100%",
//         background: "#ffffff",
//         border: "1px solid #e5e5e5",
//         boxSsizing: " border-box",
//         boxShadow: " 0px 4px 6px rgba(0, 0, 0, 0.06)",
//         borderRradius: 0,
//         padding: "15px !important",
//         fontSize: "16px !important",
//     }
// }

const Contact = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [country, setCountry] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const requestQuote = async (e) => {
        e.preventDefault();

        const data = {
            email: email,
            phone: phone,
            country: country,
            companySize: companySize,
            subject: subject,
            message: message,
        }

        const res = await axios.post(`${API_URL}/clients-quotes`, data);
        if (res.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Thank You, Your message is sent successfully",
                showConfirmButton: false,
                timer: 3000
            });
            setEmail("");
            setPhone("");
            setCountry("");
            setCompanySize("");
            setSubject("");
            setMessage("");
            navigate('/');
        } else {
            console.log("Error occured !!");
        }
    }

    return (
        <>
            <Helmet>
                <title>Request a Quote | Onlogis</title>
                <meta name="description" content="Request a quote - Give us a call or send us an email we will get back to you as soon as possible. Mention your name, email, phone, country, and company size." />
            </Helmet>
            <div className="contact-us" style={{ background: '#f7f7f7', overflow: 'hidden' }}>
                <div className="container my-container">
                    <div className="row">
                        <div className="col-md-6 mx-auto my-sm-5 my-3">
                            <h3 className="fs-3 lh-base text-center pt-3" style={{ color: '#ED7117' }}>Request a Quote</h3>
                            <p className="lead lh-base fs-6 mt-3 text-sm-start text-center" style={{ color: '#888' }}>Give us a call or send us an email and we will get back to you as soon possible</p>
                            <form onSubmit={requestQuote}>
                                <div className="form-group mb-3 position-relative">
                                    <input type="email" name="email" placeholder="Your Email" className="input" required
                                        value={email} onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <input type="text" name="phone" placeholder="Your Phone" className="input" required
                                        value={phone} onChange={e => setPhone(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <select className="form-select input" name='country' onChange={e => setCountry(e.target.value)}>
                                        <option selected disabled>Choose your country</option>
                                        {
                                            countries.map((country, index) => (
                                                <option value={country} key={index}>
                                                    {country}
                                                </option>
                                            ))
                                        }
                                    </select>
                                    {/* <CompanySizeSelect styles={styles.select} /> */}
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <select className="form-select input" name='companySize' onChange={e => setCompanySize(e.target.value)} >
                                        <option selected disabled>Choose your company size</option>
                                        {
                                            companies_size.map((size, index) => (
                                                <option value={size} key={index}>
                                                    {size}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <input type="text" name="subject" placeholder="Your subject" className="input" required
                                        value={subject} onChange={e => setSubject(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 position-relative">
                                    <textarea name="message" className='textarea' id="" cols="30" rows="10" placeholder='Your Message...' required
                                        value={message} onChange={e => setMessage(e.target.value)}>
                                    </textarea>
                                </div>
                                <div className="form-group mb-5">
                                    <button type="submit" className="btn-auth text-white fs-5">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
