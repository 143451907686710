import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../partials/Button';
import { Helmet } from 'react-helmet';

const Page404 = () => {
    return (
        <>
            <Helmet>
                <title>Onlogis - 404 Not Found</title>
            </Helmet>
            <div style={{ background: '#F7F7F7' }}>
                <div className="my-container py-4">
                    <div className="my-5 text-center py-5">
                        <h1 style={{ fontSize: '100px', color: '#ED7117' }}>404</h1>
                        <p style={{ fontSize: '25px' }}>Ooops!!</p>
                        <p style={{ fontSize: '25px' }}>THAT PAGE DOESN'T EXIST OR IS UNVAILABLE.</p>
                        <Link to="/">
                            <Button>Go Back to Home</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page404;
