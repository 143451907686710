export const MenuItems = [
    {
        ItemName: 'Home',
        url: '/',
        cName: 'nav-link'
    },
    {
        ItemName: 'Features',
        url: '/features',
        cName: 'nav-link'
    },
    {
        ItemName: 'Pricing Plans',
        url: '/pricing',
        cName: 'nav-link'
    },
    {
        ItemName: 'Privacy',
        url: '/privacy',
        cName: 'nav-link'
    }
]