import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/partials/NewNavbar';
import Home from './components/pages/Home';
import FooterApp from './components/partials/Footer';
import Features from './components/pages/Features';
import Privacy from './components/pages/Privacy';
import RequestDemo from './components/pages/RequestDemo';
import Pricing from './components/pages/NewPricing';
import Installation from './components/pages/Installation';
import TechnicalSupport from './components/pages/TechnicalSupport';
import VideoTraining from './components/pages/VideoTraining';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from './ScrollToTop';
import SignUp from './components/pages/auth/SignUp';
import SignIn from './components/pages/auth/SignIn';
import Page404 from './components/pages/Page404';
import Contact from './components/pages/Contact';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from './components/chatbot/config';
import ActionProvider from './components/chatbot/ActionProvider';
import MessageParser from './components/chatbot/MessageParser';

export const API_URL = 'http://localhost:5000/api';

function App() {
  const [showChatbot, setShowChatbot] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>

      {/* WhatsApp link */}
      <a
        href="https://wa.me/+442037692827"
        className="link whatsapp_float"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-whatsapp whatsapp-icon"></i>
      </a>

      {/* Chatbot button */}
      <button className="btn-chatbot" onClick={() => setShowChatbot(!showChatbot)}>
        {!showChatbot ? (
          <i className="fab fa-facebook-messenger"></i>
        ) : (
          <i className="fas fa-times"></i>
        )}
      </button>

      {/* Chatbot popup */}
      {showChatbot && (
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
        />
      )}

      {/* Scroll to top */}
      <ScrollToTop />

      {/* Navbar - ensure it's wrapped inside Router */}
      <Navbar />

      {/* Define routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/features" element={<Features />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/request-a-demo" element={<RequestDemo />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/user-guide" element={<Installation />} />
        <Route path="/technical-support" element={<TechnicalSupport />} />
        <Route path="/demo-training" element={<VideoTraining />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/request-a-quote" element={<Contact />} />
        <Route path="*" element={<Page404 />} />
      </Routes>

      {/* Footer - ensure it's inside Router */}
      <FooterApp />
    </Router>
  );
}

export default App;
