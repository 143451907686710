import React from 'react';
import '../css/Footer.css';
import { Link } from 'react-router-dom';
import ScrollButton from '../../ScrollButton';

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <footer className="pt-2">
                <div className="container my-container">
                    <ScrollButton />
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <img srcSet="img/logo.png" alt="" className="img-fluid mb-3" />
                            <p>
                                All in one cloud centralized database solution to <br /> manage your logistics and freight <br />
                                forwarding operations.
                            </p>
                            <div className="d-flex align-items-center justify-content-start text-center mb-3">
                                <i className="fab fa-facebook"></i>
                                <i className="fab fa-twitter"></i>
                                <i className="fab fa-linkedin"></i>
                                <i className="fab fa-whatsapp"></i>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-3">
                            <h3>More Info</h3>
                            <ul className="list-group d-flex flex-column">
                                <li className="mb-2"><i className="fas fa-chevron-right"></i> <Link style={{ color: '#797979' }} to="/" className="footer-link">Home</Link></li>
                                <li className="mb-2"><i className="fas fa-chevron-right"></i> <Link style={{ color: '#797979' }} to="/features" className="footer-link">Features</Link></li>
                                <li className="mb-2"><i className="fas fa-chevron-right"></i> <Link style={{ color: '#797979' }} to="/pricing" className="footer-link">Pricing Plans</Link></li>
                                <li className="mb-2"><i className="fas fa-chevron-right"></i> <Link style={{ color: '#797979' }} to="/privacy" className="footer-link">Privacy</Link></li>
                                <li className="mb-2"><i className="fas fa-chevron-right"></i> <Link style={{ color: '#797979' }} to="/technical-support" className="footer-link">Technical Support</Link></li>
                                <li className="mb-2"><i className="fas fa-chevron-right"></i> <Link style={{ color: '#797979' }} to="/user-guide" className="footer-link">User Guide</Link></li>
                                <li className="mb-2"><i className="fas fa-chevron-right"></i> <Link style={{ color: '#797979' }} to="/demo-training" className="footer-link">Demo Training</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto">
                            <h3>Get in touch</h3>
                            <p className="mb-3">
                                Give us a call or send us an email and we
                                will get back to you as soon possible
                            </p>
                            <p className="mb-3">
                                <i className="fas fa-home me-1"></i>
                                Simba Solution Limitied 71-75 Shelton Street <br />
                                Covert Garden, London WC2h 9JQ <br />
                                UTR: 43133 27262
                            </p>
                            <p>
                                <i className="fas fa-envelope me-2"></i>
                                Info@onlogis.com
                            </p>
                            <p>
                                <i className="fas fa-phone-alt me-2"></i>
                                + 442037692827
                            </p>
                        </div>
                    </div>
                </div>
                <div className="copy py-2">
                    <p className="lead text-center text-white pt-3">
                        &copy; Onlogis {currentYear}. All Rights Reserved
                    </p>
                </div>
            </footer>

        </>
    )
}

export default Footer;
