import React, { useState } from 'react';
import { Button } from '../partials/Button';
import '../css/Pricing.css';
// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { countries, companies_size } from '../../countries'
import Swal from 'sweetalert2'
import { API_URL } from '../../App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const NewPricing = () => {
    const [phone, setPhone] = useState("");
    const [date, setDate] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [country, setCountry] = useState("");

    const navigate = useNavigate();

    const primaryColor = {
        color: '#ED7117'
    }

    const bgInfo = {
        background: '#EBF3FA'
    }

    const sendMessageToScheduleDemo = async (e) => {
        e.preventDefault();
        const data = {
            phone: phone,
            date: date,
            companySize: companySize,
            country: country
        }
        const res = await axios.post(`${API_URL}/schedule-demo-request`, data);
        if (res.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Thank You, Your message is sent successfully",
                showConfirmButton: false,
                timer: 3000
            });
            setPhone("");
            setCountry("");
            setCompanySize("");
            setDate(null);
            navigate('/');
        } else {
            console.log("Error occured !!");
        }
    }

    return (
        <>
            <Helmet>
                <title>Onlogis - Pricing</title>
                <meta name="description" content="Onlogis offers different pricing plans to remain within your company's budget
                including startup, medium and entreprise plan" />
            </Helmet>
            <div className="top mb-sm-5 pt-5" style={{ minHeight: '400px', background: '#f7f7f7', overflow: 'hidden' }}>
                <div className="container my-container">
                    <div className="my-flex-column d-sm-flex flex-sm-row justify-content-between align-items-center">
                        <p className="text-center text-sm-start fs-5 lead my-sm-4 fw-normal lh-base mb-4" data-aos="fade-down" style={{ color: '#797979' }}>
                            Onlogis offers its customers the best value for money in the market with customized modules that live up to
                            expectations and meet the needs of your business
                        </p>
                        <img srcSet="img/pricings.png" className="img-fluid w-50 img" alt="pricing" data-aos="zoom-in-up" />
                    </div>
                </div>
            </div>
            {/* Pricing plan for mobiles */}
            <div className="container my-container mt-5 hide-from-desktop">
                <div className="row text-start my-5">
                    <div className="col-md-4 mt-5">
                        <div className="plan p-2 ">
                            <h4 className="fs-2 lh-base fw-normal text-center">Start Up Plan</h4>
                            <p className="fs-6 lh-base fw-normal text-center"> User  355$ /month</p>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Users : </span>
                                <span className="fs-3 fw-normal ms-5 ">3</span>
                            </div>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Set Up Fees : 200$</span>
                            </div>
                            <hr style={{ color: "#ED7117", height: "1px" }} />
                            <h6 className='fs-4 text-center'>List Of Modules</h6>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    HR Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Employees Informations & Administration Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Contracts Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Time and Attendance Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Leave and Absence Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Payroll Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Recruitment Functionalities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Performance Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: 'black' }}></i> Dashboard with HR KPIS
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Corporate Social Network - Real Time Colaboration
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Accounting and financial
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i>  General accounting customized per client's country
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create accounting chart or adapt a default one
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create & manage accounting and auxiliary journals
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Recording entries with necessary information
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Create accounting entry models
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Edit and view  general ledger and third party ledger
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Closing the periods at the end of the fiscal year
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: 'black' }}></i>  Plan and monitor your project budgets
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: 'black' }}></i>  Track important information through the DAF dashboard
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    CRM
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Sales department performance dashboard
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Transfer contacts to prospect pipeline
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Follow-up prospect - customer conversion activities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Quotation management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Management of lost opportunities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Email marketing
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Contacts identification through e-mail communication
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Allocation of sales teams to different projects
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: 'black' }}></i>  Personalization of marketing campaigns
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Purchase
                                </li>
                                <li className="list-group-item fs-6">
                                    <span style={primaryColor}>5</span> Purchase orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase Agreements
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Tracking Quotations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase Tender
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Automate Replenishments
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Follow Up Orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Manage Deals
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Purchase Performance Analysis
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Sale
                                </li>
                                <li className="list-group-item fs-6">
                                    <span style={primaryColor}>5</span> Quotations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Quotations Template and Deadlines
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Electronic Signature for Order Confirmation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Invoicing
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Request a down Payment
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Send a pro-forma Invoice
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Re-invoice expenses to customers
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Add and Sell subscriptions
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Manage Products
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Set Taxes
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Manage Pricing
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Freight Forwarding
                                </li>
                                <li className="list-group-item fs-6">
                                    <span style={primaryColor}>5</span> All freight operations (Air, Ocean, and Land) Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i> Comprehensive Reporting (profit and loss)
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Add multiple routes for each shipment
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Generating multiple routes in the same freight operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add different types of partners per operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Entry of different services per route
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create vendor bills and customer invoices from operations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Follow up tab for each quotation created for each service
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Automatic Customer invoices from single operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Ability to record shipping line invoices
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Job profitability analysis for each single operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Generating the final shipping declaration
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Dashboard
                                </li>
                            </ul>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Inventory and Warehouse Manegement
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Advanced barcode Scanner App
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Automate Replenishments
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Detailed Warehouse Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Packaging selection
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Shipping operations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Shipping Setup
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Delivery orders and uncoming shippment Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Acquiring up-to-date & live data on inventory procedures
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Transport Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i>  Advanced routes configuration
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Visualize Transport details
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Select Transporter on Vehicle
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add Transporter on Sales Order
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i>  Transport Rout Details
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add Transport Delivery Charge
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i>   Transport Entry Report
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i> Business and Analysis report
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i> Dashboard with key logistics sector KPI'S
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div className="plan p-2 ">
                            <h4 className="fs-2 lh-base fw-normal text-center">Medium Entreprise Plan</h4>
                            <p className="fs-6 lh-base fw-normal text-center"> User <br /> 30$ /month</p>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Users : </span>
                                <span className="fs-3 fw-normal ms-5 ">10</span>
                            </div>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Set Up Fees : 500$</span>
                            </div>
                            <hr style={{ color: "#ED7117", height: "1px" }} />
                            <h6 className='fs-4 text-center'>List Of Modules</h6>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    HR Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <span style={primaryColor}>10</span> Employees Informations & Administration Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Contracts Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Time and Attendance Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Leave and Absence Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Payroll Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Recruitment Functionalities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Performance Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Dashboard with HR KPIS
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Corporate Social Network - Real Time Colaboration
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Accounting and financial
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  General accounting customized per client's country
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create accounting chart or adapt a default one
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create & manage accounting and auxiliary journals
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Recording entries with necessary information
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Create accounting entry models
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Edit and view  general ledger and third party ledger
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Closing the periods at the end of the fiscal year
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Plan and monitor your project budgets
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Track important information through the DAF dashboard
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Customer Relationship Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Sales department performance dashboard
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Transfer contacts to prospect pipeline
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Follow-up prospect - customer conversion activities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Quotation management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Management of lost opportunities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Email marketing
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Contacts identification through e-mail communication
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Allocation of sales teams to different projects
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Personalization of marketing campaigns
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Purchase
                                </li>
                                <li className="list-group-item fs-6">
                                    <span style={primaryColor}>10</span> Purchase orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase Agreements
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Tracking Quotations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase Tender
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Automate Replenishments
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Follow Up Orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Manage Deals
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Purchase Performance Analysis
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Sale
                                </li>
                                <li className="list-group-item fs-6">
                                    <span style={primaryColor}>10</span> Quotations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Quotations Template and Deadlines
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Electronic Signature for Order Confirmation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Invoicing
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Request a down Payment
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Send a pro-forma Invoice
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Re-invoice expenses to customers
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Add and Sell subscriptions
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Manage Products
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Set Taxes
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Manage Pricing
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Freight Forwarding
                                </li>
                                <li className="list-group-item fs-6">
                                    <span style={primaryColor}>10</span> All freight operations (Air, Ocean, and Land) Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Comprehensive Reporting (profit and loss)
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Add multiple routes for each shipment
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Generating multiple routes in the same freight operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add different types of partners per operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Entry of different services per route
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create vendor bills and customer invoices from operations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Follow up tab for each quotation created for each service
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Automatic Customer invoices from single operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Ability to record shipping line invoices
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Job profitability analysis for each single operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Generating the final shipping declaration
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Dashboard
                                </li>
                            </ul>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Inventory and Warehouse Manegement
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Advanced barcode Scanner App
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Automate Replenishments
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Detailed Warehouse Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Packaging selection
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Shipping operations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Shipping Setup
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Delivery orders and uncoming shippment Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Acquiring up-to-date & live data on inventory procedures
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Transport Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Advanced routes configuration
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Visualize Transport details
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Select Transporter on Vehicle
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add Transporter on Sales Order
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Transport Rout Details
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add Transport Delivery Charge
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>   Transport Entry Report
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Business and Analysis report
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Dashboard with key logistics sector KPI'S
                                </li>
                            </ul>
                            <div className="text-center">
                                <button className="mobile-btn-buy" onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div className="plan p-2">
                            <h4 className="fs-2 lh-base fw-normal text-center">Entreprise Plan</h4>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal " >Unlimited Users</span>
                            </div>
                            <div className="mb-2 text-center" style={primaryColor}>
                                <span className="fs-3 fw-normal "  >Set Up Fees : To be Estimated</span>
                            </div>
                            <hr style={{ color: "#ED7117", height: "1px" }} />
                            <h6 className='fs-4 text-center'>List Of Modules</h6>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    HR Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Employees Informations & Administration Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Contracts Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Time and Attendance Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Leave and Absence Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Payroll Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Recruitment Functionalities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Performance Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Dashboard with HR KPIS
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Corporate Social Network - Real Time Colaboration
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Accounting and financial
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-times-circle" style={{ color: "black" }}></i> General none country customized accounting
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  General accounting customized per client's country
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create accounting chart or adapt a default one
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create & manage accounting and auxiliary journals
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Recording entries with necessary information
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Create accounting entry models
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Edit and view  general ledger and third party ledger
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Closing the periods at the end of the fiscal year
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Plan and monitor your project budgets
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Track important information through the DAF dashboard
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Customer Relationship Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Sales department performance dashboard
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Transfer contacts to prospect pipeline
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Follow-up prospect - customer conversion activities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Quotation management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Management of lost opportunities
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Email marketing
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Contacts identification through e-mail communication
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Allocation of sales teams to different projects
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Personalization of marketing campaigns
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Purchase
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase Agreements
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Tracking Quotations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Track Purchase Tender
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Automate Replenishments
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Follow Up Orders
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Manage Deals
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Purchase Performance Analysis
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Sale
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Quotations Template and Deadlines
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Electronic Signature for Order Confirmation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Invoicing
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Request a down Payment
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Send a pro-forma Invoice
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Re-invoice expenses to customers
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Add and Sell subscriptions
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Manage Products
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Set Taxes
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Manage Pricing
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Freight Forwarding
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> All freight operations (Air, Ocean, and Land) Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Comprehensive Reporting (profit and loss)
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Add multiple routes for each shipment
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Generating multiple routes in the same freight operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add different types of partners per operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Entry of different services per route
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Create vendor bills and customer invoices from operations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Follow up tab for each quotation created for each service
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Automatic Customer invoices from single operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Ability to record shipping line invoices
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Job profitability analysis for each single operation
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Generating the final shipping declaration
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Dashboard
                                </li>
                            </ul>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Inventory and Warehouse Manegement
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Advanced barcode Scanner App
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Automate Replenishments
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Detailed Warehouse Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Packaging selection
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Shipping operations
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Shipping Setup
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i> Delivery orders and uncoming shippment Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Acquiring up-to-date & live data on inventory procedures
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Transport Management
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Advanced routes configuration
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Visualize Transport details
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Select Transporter on Vehicle
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add Transporter on Sales Order
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Transport Rout Details
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>  Add Transport Delivery Charge
                                </li>
                                <li className="list-group-item fs-6">
                                    <i className="fas fa-check-circle" style={primaryColor}></i>   Transport Entry Report
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Business and Analysis report
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item fs-5 mb-2" style={bgInfo}>
                                    Dashboard with key logistics sector KPI'S
                                </li>
                            </ul>
                            <div className="text-center">
                                <button className="mobile-btn-buy" onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Onlogis Online Plans */}
            

            <div className="container my-container mt-3  hide-mobile px-0">
                <h1 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>Onlogis <span style={{ color: '#ED7117' }}>Online Hosting Plans</span></h1>
                <div className="line"></div>
                <table className="table table-bordered border shadow p-2 mb-5 bg-body rounded mt-5" >
                    <thead>
                        <tr>
                            <td className='p-0'>
                                <p>&nbsp;</p>
                            </td>
                            <td className="text-center fw-bold" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title">
                                    Start up Plan
                                </h5>
                                <span className='price'>1,000 MRU<br /> User / Mounth</span>
                            </td>
                            <td className="text-center fw-bold p-0" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title" style={{ left: '28px' }}>
                                    Medium Plan
                                </h5>
                                <span className='price'>1,500 MRU <br /> User / Mounth</span>
                            </td>
                            <td className="text-center fw-bold" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title" style={{ left: '30px' }}>
                                    Entreprise Plan
                                </h5>
                                <span className='price'>Request a Quote</span>
                            </td> 
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}>Users</td>
                            <td className="text-center fs-3" style={primaryColor} >
                                1
                            </td>
                            <td className="text-center fs-3" style={primaryColor} >
                                3
                            </td>
                            <td className="text-center fs-3" style={primaryColor} >
                                Unlimited
                            </td> 
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}>Modules</td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> 
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Sales</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Inventory</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> 
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Purchase</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Sales</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Purchase</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Contacts</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Payment</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>HR Management</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Accounting</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Freight</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Vessel</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Transport</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Custom</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Access Rights</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Multi Companies</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            
                            <td className="p-2 fs-3 w-50 " style={bgInfo}></td>
                            <td className="text-center fs-3">
                            <div className="text-center">
                                <button type="button" className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '12px 18px' }} onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel" style={primaryColor}>Information</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Suggest us a think and we will get back to you</p>
                                    <form>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='companySize' onChange={e => setCompanySize(e.target.value)}>
                                                <option selected disabled>Choose your company size</option>
                                                {
                                                    companies_size.map((size, index) => (
                                                        <option value={size} key={index}>{size}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='country' onChange={e => setCountry(e.target.value)}>
                                                <option selected disabled>Choose your country</option>
                                                {
                                                    countries.map((country, index) => (
                                                        <option value={country} key={index}>{country}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <input type="text" name="phone" placeholder="Your Phone" className="input" required
                                                value={phone} onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 position-relative text-start">
                                            <label htmlFor="date" className='text-start'>Choose the date and time </label>
                                            <input type="datetime-local" name="date" className="input form-control" required
                                                value={date} onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={sendMessageToScheduleDemo} type="submit" className="btn btn-secondary" data-bs-dismiss="modal" style={{ background: '#ED7117' }}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                            </td>
                            <td className="text-center fs-3">
                            <div className="text-center">
                                <button type="button" className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '12px 18px' }} onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                            
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel" style={primaryColor}>Information</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Suggest us a think and we will get back to you</p>
                                    <form>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='companySize' onChange={e => setCompanySize(e.target.value)}>
                                                <option selected disabled>Choose your company size</option>
                                                {
                                                    companies_size.map((size, index) => (
                                                        <option value={size} key={index}>{size}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='country' onChange={e => setCountry(e.target.value)}>
                                                <option selected disabled>Choose your country</option>
                                                {
                                                    countries.map((country, index) => (
                                                        <option value={country} key={index}>{country}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <input type="text" name="phone" placeholder="Your Phone" className="input" required
                                                value={phone} onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 position-relative text-start">
                                            <label htmlFor="date" className='text-start'>Choose the date and time </label>
                                            <input type="datetime-local" name="date" className="input form-control" required
                                                value={date} onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={sendMessageToScheduleDemo} type="submit" className="btn btn-secondary" data-bs-dismiss="modal" style={{ background: '#ED7117' }}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    

                    

                    
                            </td>
                            <td>
                            <div className="text-center">
                                <button type="button" className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '12px 18px' }} onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>

{/* Onlogis Local Plans */}
            <div className="container my-container mt-3  hide-mobile px-0">
                <h1 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>Onlogis <span style={{ color: '#ED7117' }}>Local Hosting Plans</span></h1>
                <div className="line"></div>
                <table className="table table-bordered border shadow p-2 mb-5 bg-body rounded mt-5" >
                    <thead>
                        <tr>
                            <td className='p-0'>
                                <p>&nbsp;</p>
                            </td>
                            <td className="text-center fw-bold" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title">
                                    Start up Plan
                                </h5>
                                <span className='price'>200 MRU<br /> User / Mounth</span>
                            </td>
                            <td className="text-center fw-bold p-0" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title" style={{ left: '28px' }}>
                                    Medium Plan
                                </h5>
                                <span className='price'>300 MRU <br /> User / Mounth</span>
                            </td>
                            <td className="text-center fw-bold" style={{ position: 'relative', background: '#ED7117', color: "#fff", width: '250px', height: '250px' }}>
                                <h5 className="mb-3 title" style={{ left: '30px' }}>
                                    Entreprise Plan
                                </h5>
                                <span className='price'>Request a Quote</span>
                            </td> 
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}>Users</td>
                            <td className="text-center fs-3" style={primaryColor} >
                                1
                            </td>
                            <td className="text-center fs-3" style={primaryColor} >
                                3
                            </td>
                            <td className="text-center fs-3" style={primaryColor} >
                                Unlimited
                            </td>
                            
                            {/* <td className="text-center fs-3" style={primaryColor} >
                                Unlimited
                            </td> *
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}>Modules</td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}>Modules</td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            <td style={bgInfo}><p>&nbsp;</p></td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> 
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Sales</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Inventory</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Sales</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Purchase</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Contacts</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Payment</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>HR Management</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Accounting</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Freight</h2></td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>                            
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Vessel</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Transport</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Custom</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Access Rights</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50" style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Multi Companies</h2></td>  
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                                                    
                            <td className="text-center">
                                <i className="fas fa-times-circle fa-2x" style={{ color: 'black' }}  ></i>
                            </td>
                            <td className="text-center" style={primaryColor} >
                                <i className="fas fa-check-circle fa-2x" style={primaryColor}></i>
                            </td>
                            {/* <td style={bgInfo}><p>&nbsp;</p></td> */}
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50 " style={bgInfo}><h2 style={{ color: '#ED7117', fontSize: '1.7rem' }}>Set Up Fees</h2></td>
                            <td className="text-center fs-3" style={primaryColor}>
                                5,000 MRU
                            </td>
                            <td className="text-center fs-3" style={primaryColor}>
                                10,000 MRU
                            </td>
                            <td className="text-center fs-3" style={primaryColor}>
                                Contact Us for Pricing
                            </td>
                        </tr>
                        <tr>
                            <td className="p-2 fs-3 w-50 " style={bgInfo}></td>
                            <td className="text-center fs-3">
                            <div className="text-center">
                                <button type="button" className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '12px 18px' }} onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel" style={primaryColor}>Information</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Suggest us a think and we will get back to you</p>
                                    <form>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='companySize' onChange={e => setCompanySize(e.target.value)}>
                                                <option selected disabled>Choose your company size</option>
                                                {
                                                    companies_size.map((size, index) => (
                                                        <option value={size} key={index}>{size}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='country' onChange={e => setCountry(e.target.value)}>
                                                <option selected disabled>Choose your country</option>
                                                {
                                                    countries.map((country, index) => (
                                                        <option value={country} key={index}>{country}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <input type="text" name="phone" placeholder="Your Phone" className="input" required
                                                value={phone} onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 position-relative text-start">
                                            <label htmlFor="date" className='text-start'>Choose the date and time </label>
                                            <input type="datetime-local" name="date" className="input form-control" required
                                                value={date} onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={sendMessageToScheduleDemo} type="submit" className="btn btn-secondary" data-bs-dismiss="modal" style={{ background: '#ED7117' }}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                            </td>
                            <td className="text-center fs-3">
                            <div className="text-center">
                                <button type="button" className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '12px 18px' }} onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel" style={primaryColor}>Information</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Suggest us a think and we will get back to you</p>
                                    <form>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='companySize' onChange={e => setCompanySize(e.target.value)}>
                                                <option selected disabled>Choose your company size</option>
                                                {
                                                    companies_size.map((size, index) => (
                                                        <option value={size} key={index}>{size}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='country' onChange={e => setCountry(e.target.value)}>
                                                <option selected disabled>Choose your country</option>
                                                {
                                                    countries.map((country, index) => (
                                                        <option value={country} key={index}>{country}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <input type="text" name="phone" placeholder="Your Phone" className="input" required
                                                value={phone} onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 position-relative text-start">
                                            <label htmlFor="date" className='text-start'>Choose the date and time </label>
                                            <input type="datetime-local" name="date" className="input form-control" required
                                                value={date} onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={sendMessageToScheduleDemo} type="submit" className="btn btn-secondary" data-bs-dismiss="modal" style={{ background: '#ED7117' }}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                            </td>
                            <td>
                            <div className="text-center">
                                <button type="button" className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '12px 18px' }} onClick={() => navigate("/request-a-demo")}>
                                CONTACT US
                                </button>
                            </div>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>

            
            <div className="container my-container my-5 text-center" style={{ overflow: 'hidden' }}>
                <div className="pt-5">
                    <h4 className="fs-2">Ready to get started?</h4>
                    <h2 className="fs-1 mt-3 mb-4">
                        Schedule a demo, and try Onlogis for 30 days free trial
                    </h2><div className="text-center">
                                <button type="button" className="btn btn-default" style={{ background: '#ED7117', color: '#fff', padding: '12px 18px' }} onClick={() => navigate("/request-a-demo")}>
                                Request A Demo
                                </button>
                            </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel" style={primaryColor}>Information</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>Suggest us a think and we will get back to you</p>
                                    <form>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='companySize' onChange={e => setCompanySize(e.target.value)}>
                                                <option selected disabled>Choose your company size</option>
                                                {
                                                    companies_size.map((size, index) => (
                                                        <option value={size} key={index}>{size}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <select className="form-select input" name='country' onChange={e => setCountry(e.target.value)}>
                                                <option selected disabled>Choose your country</option>
                                                {
                                                    countries.map((country, index) => (
                                                        <option value={country} key={index}>{country}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-3 position-relative">
                                            <input type="text" name="phone" placeholder="Your Phone" className="input" required
                                                value={phone} onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 position-relative text-start">
                                            <label htmlFor="date" className='text-start'>Choose the date and time </label>
                                            <input type="datetime-local" name="date" className="input form-control" required
                                                value={date} onChange={e => setDate(e.target.value)}
                                            />
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={sendMessageToScheduleDemo} type="submit" className="btn btn-secondary" data-bs-dismiss="modal" style={{ background: '#ED7117' }}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img srcSet="img/technical-support1.png" className="img-fluid mt-5" alt="Schedule a demo" />
            </div>
        </>
    )
}

export default NewPricing;
