import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { MenuItems } from './MenuItems';
import { DropdownItems } from './DropdownItems';
import '../css/NewNavbar.css';

const Navbar = () => {

    const [click, setClick] = useState(false)
    const [button, setButton] = useState(false)

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton()
    }, [])

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className="navbar navbar-expand-lg sticky-top top-0">
                <div className="container my-container">
                    <Link to="/" className="navbar-brand me-0" onClick={closeMobileMenu}>
                        <img srcSet="img/logo.png" alt="Onlogis Logo" className="img-fluid w-sm-100" />
                    </Link>
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    <ul className={click ? 'navbar-nav active' : 'navbar-nav'}>
                        {MenuItems.map((item, index) => {
                            return (
                                <li className="nav-item" key={index}>
                                    <Link to={item.url} onClick={closeMobileMenu} className={item.cName}>{item.ItemName}  </Link>
                                </li>
                            )
                        })}
                        <li className="nav-item dropdown">
                            <Link to="" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Onlogis Support
                            </Link>
                            <ul className="dropdown-menu p-1 p-sm-0" aria-labelledby="navbarDropdown">
                                {DropdownItems.map((item, index) => {
                                    return (
                                        <li className="nav-item" key={index}>
                                            <Link to={item.url} onClick={closeMobileMenu} className={item.cName}>{item.ItemName}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                        {/* <li className="nav-item">
                            <Link to="/sign-up" className="nav-link" onClick={handleClick}>
                                Sign up
                            </Link>
                        </li> */}
                        <div className="ms-sm-1 ms-0">
                            {
                                button
                                    ?
                                    (
                                        <Link to="/request-a-demo" onClick={handleClick}>
                                            <Button buttonSize="btn-lg">CONTACT US</Button>
                                        </Link>
                                    )
                                    :
                                    (
                                        <Link to="/sign-in" onClick={closeMobileMenu}>
                                            <Button buttonSize="btn-mobile">Sign in</Button>
                                        </Link>
                                    )
                            }
                        </div>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar;