import React from 'react';
import '../../App.css';
import { Helmet } from 'react-helmet';
import { Button } from '../partials/Button';
import { Link } from 'react-router-dom';

function VideoTraning() {
    return (
        <>
            <Helmet>
                <title>Onlogis - Demo Training</title>
                <meta name="description" content="Preview the video demo of each feature to help guide your understanding  of the logistics and transportation management software" />
            </Helmet>
            <div className="top mb-5" style={{ minHeight: '400px', background: '#f7f7f7' }}>
                <div className="container my-container">
                    <div className="my-flex-column d-sm-flex flex-sm-row justify-content-between align-items-center">
                        <p data-aos="fade-down" className="text-center text-sm-start fs-5 lead my-sm-4 fw-normal lh-base mb-4" style={{ color: '#797979' }}>
                            To support onlogis customers in the process of understanding and using the logistics management software, a set of demo videos are on display. <br />
                            Preview the demo of each feature to help guide your understanding
                        </p>
                        <img srcSet="img/video1.png" className="img-fluid mt-4 w-50 img-mobile" data-aos="zoom-in-up" alt="Demo Training" />
                    </div>
                </div>
            </div>
            <div className="container my-container my-5">
                <h1 className="fw-bold fs-3 text-center mb-2 lh-base" style={{ color: '#797979' }}>Demo <span style={{ color: '#ED7117' }}>Training</span></h1>
                <div className="line"></div>
                <div className="row text-center mb-5 mt-5">
                <div className="row text-center mb-5 mt-5">
    <div className="mx-auto center">
        
        <iframe
            className="mt-3"
            width="600"
            height="340"
            src="https://www.youtube.com/embed/5zWW7uwpfSM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    </div>
    <div>
            <h2 className="col-md-3 mx-auto center">INTRODUCTION OVERVIEW</h2>
        </div>
</div>

</div>

            
                <div className="row mt-3 text-center">
                    <div className="col-md-3 mx-auto center width-50">
                        <Button>
                            <Link className='link' to="/pricing">Choose Your Plan</Link>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoTraning;
